import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started"
    }}>{`Getting started`}</h1>
    <p><strong parentName="p">{`Webhooks`}</strong>{` enable real-time communication between applications. When specific events happen, one app automatically sends data to another.
This integration streamlines workflows, syncs systems without constant polling, and integrates third-party services.`}</p>
    <p>{`LiveSession triggers webhooks via preset events, with ongoing expansion.
When a configured event occurs, our systems promptly notify your servers, delivering detailed event data.`}</p>
    <h2 {...{
      "id": "configuration"
    }}>{`Configuration`}</h2>
    <p>{`You can customize your setup within LiveSession's `}<inlineCode parentName="p">{`Settings`}</inlineCode>{` menu.
In the `}<inlineCode parentName="p">{`API > Webhooks`}</inlineCode>{` section, oversee the URLs that facilitate LiveSession's interaction with your servers.`}</p>
    <p><em parentName="p">{`Note that you must have an Admin or Owner role to manage webhook settings.`}</em></p>
    <h2 {...{
      "id": ""
    }}><img alt="Webhooks settings" src={require("./webhooks1.png")} /></h2>
    <p>{`Endpoints have the following attributes:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`URL`}</strong>{`: Each endpoint is uniquely identified by its URL, utilizing either the HTTP or HTTPS scheme. This URL signifies the server address that LiveSession establishes communication with.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Website`}</strong>{`: The 'Website' field refers to an identifier for your chosen LiveSession-enabled website.`}</p>
      </li>
    </ul>
    <p><img alt="Create webhook modal" src={require("./webhooks2.png")} /></p>
    <p>{`Once you've configured your endpoints, you can begin assigning them to specific events using `}<a parentName="p" {...{
        "href": "https://livesession.io/help/how-do-the-alerts-work/"
      }}>{`Alerts`}</a>{`.`}</p>
    <p><img alt="Create alert with webhook" src={require("./webhooks3.png")} /></p>
    <h2 {...{
      "id": "delivery"
    }}>{`Delivery`}</h2>
    <p>{`When an event ready for webhook transmission occurs within LiveSession, an event payload is crafted.
If you've set up endpoints for that specific event type, the payload gets lined up for delivery.`}</p>
    <p>{`These "trigger-ready" events encompass actions ideal for webhook interaction.
For instance, when a session event is created, it's trigger-ready.`}</p>
    <p>{`Event delivery typically takes mere seconds, though a strict FIFO order isn't assured.
Given potential event volume, the webhook platform prioritizes throughput over rigid sequencing.`}</p>
    <p>{`Once an event is dispatched, a payload signature is computed and included in the request.
The request sent to your chosen endpoint bears these details:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`HTTP method is `}<inlineCode parentName="p">{`POST`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Content-Type of the request body is `}<inlineCode parentName="p">{`application/json`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`There will be a request header named `}<inlineCode parentName="p">{`LiveSession-Signature`}</inlineCode>{`; this includes the request signature which the recipient should verify.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Every request body will contain a JSON object with the following fields:`}</p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`message id`}</inlineCode>{` - the message identifier`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`webhook_id`}</inlineCode>{` - the webhook identifier`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`webhook_type`}</inlineCode>{` - the webhook type`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`api_version`}</inlineCode>{` - the webhook API version`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`account id`}</inlineCode>{` - the account identifier`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`website id`}</inlineCode>{` - the website identifier`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`created_at`}</inlineCode>{` - the message creation timestamp`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`payload`}</inlineCode>{` - the event payload`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Below is a representation of the entire HTTP request:`}</p>
    <pre><code parentName="pre" {...{}}>{`POST /webhooks HTTP/1.1
Host: example.com
Content-Type: application/json; charset=utf-8
LiveSession-Signature: /NLAt29+UdUod1lyzeWcbNigjzGfvtusP44dNKt4Q3U=
User-Agent: LiveSessionWebhooks/1.0

{
  "message_id": "d5932de4-a8da-4546-4439-3c640ba8dc05",
  "webhook_id": "542de45",
  "webhook_type": "session.event",
  "api_version": "v1.0",
  "account_id": "3ca3b1b",
  "website_id": "8d53ea3",
  "created_at": 1691161881,
  "payload": {
    "visitor": {
      "id": "e4d5932d-4439-4546-a8da-a8d40bc053c6",
      "name": "John Doe",
      "email": "john.doe@livesession.io",
      "params": [
        {
          "name": "plan",
          "value": "pro"
        }
      ],
      "geolocation": {
        "country_code": "PL",
        "city": "Wroclaw",
        "region": "Dolnoslaskie"
      }
    },
    "event_name": "Error",
    "time": 1691161881,
    "count": 1,
    "value": "ProductCatalog: product is not valid"
  }
}
`}</code></pre>
    <h4 {...{
      "id": "throttling"
    }}>{`Throttling`}</h4>
    <p>{`To prevent your servers from being overwhelmed or unintentionally participating in a service disruption on your webhook endpoint,
LiveSession limits the number of events sent for a given webhook to a maximum of 1000 per minute.`}</p>
    <p>{`If you've configured an endpoint for a high-volume event flow, like a frequently triggered custom event during user sessions,
expect possible webhook delays. When the number of events for an endpoint exceeds 1000 per minute, the extra events will be queued.
If the events happen really fast for a little while and then slow down, all the events might still arrive,
but it could take longer than when they first happened. But if the fast rate continues for a long time, some events might not be delivered.
If an event is undeliverable for 24 hours, it's treated as failed, and no more tries will be made.`}</p>
    <p><em parentName="p">{`The limit of 1000 events per minute is per account.`}</em></p>
    <h4 {...{
      "id": "retry-behavior"
    }}>{`Retry behavior`}</h4>
    <p>{`LiveSession attempts to deliver a given event to your webhook endpoint for up to 24 hours with an exponential back off.`}</p>
    <h2 {...{
      "id": "verify-the-webhook"
    }}>{`Verify the webhook`}</h2>
    <p>{`Before you proceed with responding to a webhook, it's crucial to ensure that the webhook originated from LiveSession.
This verification can be accomplished by calculating a digital signature.`}</p>
    <p>{`Every webhook request incorporates a base64-encoded
HMAC-SHA256 signature included in LiveSession-Signature header.
This header is created using the app's client secret in conjunction with the data transmitted in the request.`}</p>
    <p>{`To compute the HMAC digest, follow this algorithm written in Python:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-py"
      }}>{`import hmac
import hashlib
import base64

# Your webhook secret key (convert to bytes if not already)
secret_key = b'your_secret_key_here'

# Received data from the webhook payload (convert to bytes if not already)
received_data = b'received_data_here'

# Received base64-encoded HMAC signature from the webhook headers
received_signature_base64 = "received_signature_here"  # Replace with actual received signature

# Function to compute HMAC-SHA256 signature and return base64-encoded result
def compute_hmac_sha256_base64(data, secret_key):
    hashed = hmac.new(secret_key, data, hashlib.sha256)
    return base64.b64encode(hashed.digest()).decode('utf-8')

# Calculate the base64-encoded HMAC-SHA256 signature for the received data
calculated_signature_base64 = compute_hmac_sha256_base64(received_data, secret_key)

# Compare the received signature with the calculated signature
if received_signature_base64 == calculated_signature_base64:
    print("Webhook signature is valid. Request is authentic.")
else:
    print("Webhook signature is invalid. Request might be tampered.")
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      